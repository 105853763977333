import { useTheme } from "styled-components";

export const FullyChargedIcon = (): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.success;

  return (
    <>
      <rect width="5" height="4" fill={fill} />
    </>
  );
};
