import { PropsWithChildren, useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "../../hooks";

const Wrapper = styled.div<{
  open: boolean;
}>`
  position: fixed;
  display: ${(props) => (props.open ? "flex" : "none")};
  justify-content: center;
  align-items: center;

  z-index: 2;

  top: -1.5rem;
  left: 0;

  width: 100%;
  height: 100%;
`;

const ModalWrapper = styled.div`
  position: relative;

  background-color: ${(props) => props.theme.colors.modalBackground[500]};
  box-shadow: 0px 0px 24px #000000;
  border-radius: 4px;

  z-index: 3;
  opacity: 1;

  width: clamp(200px, 250px, 90%);
`;

const Header = styled.div`
  width: 100%;
  height: auto;

  padding: 2rem;
  background: ${(props) => props.theme.colors.text[100]};
`;

const ModalContent = styled.div`
  padding: 1rem;
`;

export interface ModalProps {
  open: boolean;
  header?: JSX.Element;
  onClose: () => void;
}

export const Modal = ({
  open,
  header,
  onClose,
  children,
}: PropsWithChildren<ModalProps>): JSX.Element => {
  const ref = useRef(null);

  useOnClickOutside(ref, onClose);

  window.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
      onClose();
    }
  });

  return (
    <Wrapper open={open}>
      <ModalWrapper ref={ref}>
        {header && <Header>{header}</Header>}
        <ModalContent>{children}</ModalContent>
      </ModalWrapper>
    </Wrapper>
  );
};
