export enum ChargeStateIconType {
  CHARGING = "CHARGING",
  CHARGING_UNTIL = "CHARGING_UNTIL",
  EXECUTING_ACTION = "EXECUTING_ACTION",
  FULLY_CHARGED = "FULLY_CHARGED",
  NOT_PLUGGED_IN = "NOT_PLUGGED_IN",
  NOT_REACHABLE = "NOT_REACHABLE",
  PAUSED = "PAUSED",
  PLUGGED_IN_NOT_CHARGING = "PLUGGED_IN_NOT_CHARGING",
}
