import { useTheme } from "styled-components";

export const ChargingIcon = (): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.success;

  return (
    <>
      <path d="M2 5L4.66667 0V3H6L3.33333 8V5H2Z" fill={fill} />
      <path opacity="0.5" d="M7 3L6 5H8V3H7Z" fill={fill} />
      <path opacity="0.5" d="M1 5L2 3L1.74846e-07 3L0 5L1 5Z" fill={fill} />
    </>
  );
};
