import { useTheme } from "styled-components";

export const ExecutingActionIcon = (): JSX.Element => {
  const theme = useTheme();
  const { 500: fill } = theme.colors.text;

  return (
    <>
      <g opacity="0.5">
        <path
          d="M4 1C4.59334 1 5.17336 1.17595 5.66671 1.50559C6.16006 1.83524 6.54458 2.30377 6.77164 2.85195C6.9987 3.40013 7.05811 4.00333 6.94236 4.58527C6.8266 5.16721 6.54088 5.70176 6.12132 6.12132C5.70176 6.54088 5.16721 6.8266 4.58527 6.94236C4.00333 7.05811 3.40013 6.9987 2.85195 6.77164C2.30377 6.54458 1.83524 6.16006 1.50559 5.66671C1.17595 5.17336 1 4.59334 1 4H2.2C2.2 4.35601 2.30557 4.70402 2.50335 5.00003C2.70114 5.29603 2.98226 5.52675 3.31117 5.66298C3.64008 5.79922 4.002 5.83487 4.35116 5.76541C4.70033 5.69596 5.02106 5.52453 5.27279 5.27279C5.52453 5.02106 5.69596 4.70033 5.76541 4.35116C5.83487 4.002 5.79922 3.64008 5.66298 3.31117C5.52675 2.98226 5.29603 2.70114 5.00003 2.50335C4.70402 2.30557 4.35601 2.2 4 2.2V1Z"
          fill={fill}
        />
      </g>
    </>
  );
};
