import { CSSProperties } from "react";
import { ChargeStateIconType } from "./ChargeStateIconType";
import { ChargingIcon } from "./icons/ChargingIcon";
import { ChargingUntilIcon } from "./icons/ChargingUntilIcon";
import { ExecutingActionIcon } from "./icons/ExecutingActionIcon";
import { FullyChargedIcon } from "./icons/FullyChargedIcon";
import { NotPluggedInIcon } from "./icons/NotPluggedInIcon";
import { NotReachableIcon } from "./icons/NotReachableIcon";
import { PausedIcon } from "./icons/PausedIcon";
import { PluggedInNotChargingIcon } from "./icons/PluggedInNotChargingIcon";

interface Props {
  type: ChargeStateIconType;
  size?: number;
  style?: CSSProperties;
  onClick?: () => void;
}

export const ChargeStateIcon = ({
  style,
  size,
  type,
  onClick,
}: Props): JSX.Element => {
  return (
    <svg
      style={style}
      width={size ?? "8"}
      height={size ?? "8"}
      viewBox="0 0 8 8"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      {chargeStateIconTypeToChargeStateIcon(type)}
    </svg>
  );
};

const chargeStateIconTypeToChargeStateIcon = (type: ChargeStateIconType) => {
  switch (type) {
    case ChargeStateIconType.CHARGING:
      return <ChargingIcon />;

    case ChargeStateIconType.CHARGING_UNTIL:
      return <ChargingUntilIcon />;

    case ChargeStateIconType.EXECUTING_ACTION:
      return <ExecutingActionIcon />;

    case ChargeStateIconType.FULLY_CHARGED:
      return <FullyChargedIcon />;

    case ChargeStateIconType.NOT_PLUGGED_IN:
      return <NotPluggedInIcon />;

    case ChargeStateIconType.NOT_REACHABLE:
      return <NotReachableIcon />;

    case ChargeStateIconType.PAUSED:
      return <PausedIcon />;

    case ChargeStateIconType.PLUGGED_IN_NOT_CHARGING:
      return <PluggedInNotChargingIcon />;
  }
};
