import { CSSProperties, PropsWithChildren } from "react";
import styled, { DefaultTheme } from "styled-components";
import { Spacing } from "./constraints/Spacing";
import { mixins } from "./mixins";

interface Props {
  backgroundColor?: BackgroundColor;
  opacity?: number;
  padding?: Spacing;
  style?: CSSProperties;
}

export const BackgroundColoredView = ({
  backgroundColor,
  children,
  opacity,
  style,
  padding,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <Container style={style} padding={padding}>
      <StyledBackground backgroundColor={backgroundColor} opacity={opacity} />
      {children}
    </Container>
  );
};

const Container = styled.div<{ padding?: Spacing }>`
  ${(props) => mixins.padding(props.padding)}

  position: relative;
  overflow: hidden;
`;

const StyledBackground = styled.div<Props>`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: ${(props) =>
    cardBackgroundColorToColor({
      backgroundColor: props.backgroundColor,
      theme: props.theme,
    })};

  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
`;

const cardBackgroundColorToColor = ({
  backgroundColor,
  theme,
}: {
  backgroundColor?: BackgroundColor;
  theme: DefaultTheme;
}) => {
  switch (backgroundColor) {
    case "SUCCESS":
      return theme.colors.success["500"];

    case "TEXT":
      return theme.colors.text["500"];

    case "DANGER":
      return theme.colors.danger["500"];

    default:
      return theme.colors.background["500"];
  }
};

export type BackgroundColor = "NORMAL" | "SUCCESS" | "TEXT" | "DANGER";
